.terms-and-conditions {
    padding-bottom: 32px;
}

.t-a-c-header {
    @apply text-2xl;
    @apply border-b;
    text-align: center;
    padding-bottom: 12px;
    margin-bottom: 32px;
}

.terms-and-conditions section {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 8px;
    @apply text-xl;
}

.terms-and-conditions p {
    width: 100%;
    padding-bottom: 24px;
}
