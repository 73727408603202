#root {
    --calendar-cell-width: 100px;
    --calendar-cell-height: 15px;
}

.calendar-container {
    display: flex;
    flex-shrink: 1;
    flex-direction: row;
    /* overflow-y: auto; */
    align-items: center;
    justify-content: center;
    max-height: 600px;
    padding: 0;
    overflow-y: scroll;
    cursor: pointer;
}

.calendar-height-container {
    padding-top: 200px;
    height: 1000px;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.calendar-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.calendar-day-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    border-bottom: 1px solid;
    @apply border-dark-border;
}

.time-copy-icon:hover {
    filter: brightness(80%);
}

.time-scale {
    @apply pt-2;
    width: 65px;
    @apply text-xs;
    @apply text-dark-text-color;
}

.time-scale-cell {
    height: var(--calendar-cell-height);
}

.calendar-cell {
    height: var(--calendar-cell-height);
    flex: 1;
    flex-shrink: 1;
    border-left: 1px solid gray;
    @apply border-dark-border;
    padding-left: 8px;
    @apply text-xs;
}

.calendar-cell:nth-of-type(4n + 1) {
    border-bottom: 1px solid gray;
}

.calendar-col {
    height: 100%;
    flex: 1;
}

.calendar-absolute-container {
    position: absolute;
    width: 100%;
}

.calendar-remove-x {
    position: absolute;
    right: 3px;
    top: 3px;
}

.calendar-remove-x:hover {
    filter: brightness(70%);
}

.calendar-time-slot-relative-container {
    position: relative;
    width: 90%;
    margin-left: 5%;
}

.calendar-time-slot {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* word-wrap: none; */
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
    @apply border-dark-border;
    @apply rounded-md;
    @apply text-sm;
    @apply bg-blue-800;
    border: 1px solid;
    @apply border-dark-text-color;
}
