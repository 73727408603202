button {
    word-wrap: none;
    @apply h-action;
}

.btn-primary {
    @apply rounded px-4 py-2 font-bold;
    @apply bg-primary-action text-white;
}

.btn-primary:hover {
    @apply bg-primary-hover;
}

.btn-primary:active {
    @apply bg-blue-900;
}

.btn-primary:disabled {
    @apply bg-disabled;
}

.btn-primary:disabled:hover {
    @apply bg-disabled;
}

.btn-secondary {
    @apply rounded px-4 py-2 font-bold;
    @apply bg-secondary-action;
    @apply border border-text-color;
}

.btn-secondary:hover {
    @apply bg-secondary-hover;
}

.btn-secondary:active {
    @apply bg-secondary-active;
}
