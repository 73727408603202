.header {
    border-bottom: 1px solid var(--border-color);
}

.headerRoute {
    cursor: pointer;
}

.headerRoute:hover {
    background-color: var(--background-hover-color);
}
