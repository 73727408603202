@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: auto !important;
}

#root {
    width: 100vw;
    height: 100vh;

    --background-color: rgb(15, 23, 42);
    --background-hover-color: rgb(25, 40, 76);

    --text-color: #d7e1ee;
    --dark-text-color: #a4aebb;

    --selected-color: #319ed2;

    --border-color: #243448;

    --input-color: #374151;

    color: var(--text-color);

    overflow-anchor: none;
}

body {
    min-width: 100vw;
    min-height: 100vh;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue,
        Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
        Noto Color Emoji;
    overflow-x: hidden;
    background-color: var(--background-color);

    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.spacedRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.spacedRow > * {
    padding-left: 8px;
    padding-right: 8px;
}

.appContainer {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    padding-right: 8px;
}

.app {
    background-color: var(--background-color);
    flex-direction: column;
    display: flex;
    flex: 1;
    align-items: center;
    @apply max-w-max-app;
    @apply w-app;
}

::-webkit-scrollbar {
    width: 10px !important;
}

::-webkit-scrollbar-track {
    background: #424242 !important;
}

::-webkit-scrollbar-thumb {
    background: #888 !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #888 !important;
}

input {
    height: 40px;
}

.view {
    @apply flex h-screen w-screen flex-col items-center pt-3;
}

*[hidden] {
    display: none !important;
}
