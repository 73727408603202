.divider {
    display: flex;
    flex: row;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.dividerText {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 20px;
}

.dividerLine {
    margin-top: 6px;
    flex: 1;
    background-color: var(--border-color);
    height: 1.5px;
}
